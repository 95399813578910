import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons'
import image from '../images/unicom-innovation-center.jpg'

class InnovationSection extends React.Component {
  render() {
    return (
      <>
        <section className="news-featured-area bg-white">
          <MDBContainer className="px-lg-4">
            <MDBRow>
              <MDBCol lg="6">
                <div className="mt-5 mt-lg-0 pr-lg-5">
                <MDBAnimation reveal type="fadeInLeft" duration="1587ms">
                  <div className="mt-5 mt-lg-0 pr-5">
                    <h3 className="font-alt title-xs-large title-extra-large">
                      <span className="font-w-700 text-gradient-blue-light-blue">Transform</span> the way you work
                    </h3>
                    <p className="mt-4 mb-4 font-alt font-w-400 text-large">
                      <span className="font-w-700">
                        UNICOM Digital
                      </span>{' '}
                      delivers financing and investment structures, strategic 
                      transformation services and new digital solutions that solve complex 
                      technology problems across enterprise landscapes throughout the globe.
                    </p>
                    <div className="d-none d-sm-block mb-3">
                      <a href="https://www.unicom.digital" 
                        target="_blank" 
                        rel="noopener"
                        className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                        style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />{' '} Visit
                      </a>
                    </div>
                  </div>
                </MDBAnimation>
                </div>
              </MDBCol>

              <MDBCol lg="6">
                <MDBAnimation reveal type="fadeIn" duration="3s" delay="0.35s">
                  <a href="https://www.unicom.digital" target="_blank" rel="noopener" style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }} >
                    <img
                      src={image}
                      className="img-fluid rounded mt-2 innovation-box"
                      alt="UNICOM Digital avatar"
                      loading="lazy"
                    /> 
                  </a>

                  <MDBCol lg="12" md="12" className="mt-4 text-center d-block d-sm-none d-md-none d-xl-none" >
                    <a href="https://www.unicom.digital" className="btn Ripple-parent btn-mdb-color mr-0 mt-xl-3 mb-5" > 
                      <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" /> Visit
                    </a>
                  </MDBCol>
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </>
    )
  }
}
export default InnovationSection
